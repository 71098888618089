@tailwind base;
@tailwind components;
@tailwind utilities;
html{
    overflow-x: hidden;
}
body{
    font-family: "Font_FB", Sans-serif;
    direction: rtl;
    overflow-x: hidden;
}
.opicty{
    opacity: 0;
}
.text-xl{
    font-size: 26px;
}
.container{
    max-width: 1200px;
    padding: 0 15px;
}


header{
    padding: 9px 10px;
    text-align: center;
    background-color: #508F89;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;
}
header.scrolled{
    opacity: 1;
    visibility: visible;
}
.logo{
    width: 250px;
    height: 50px;
    object-fit: cover;
    margin: 0 auto;
}

.bg-main {
    background: url("/public/images/mainBG.gif");
    
    
}

.bg-video-main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -4;
    transition: all 0.5s ease;
}
.bg-video-main iframe {
    width: 100%;
    height: 170%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.banner-area-main::after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: radial-gradient(at top center, rgb(80, 143, 137, 0.4) 10%, #508F89 85%);
    z-index: -1;
}
.banner-area-main a{
    padding: 7px 30px;
}


.company-logo-box{
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}
.swiper{
    padding: 0 40px 40px !important;
    margin-left: 30px;
}


.swiper-pagination-bullet-active {
    background: #28ccbd !important;
}
.swiper-button-prev, .swiper-button-next{
    color: #fff !important;
    background-color: #28ccbd !important;
    padding: 15px;
    width: 35px !important;
    height: 35px !important;
    box-shadow: 0 0 7px -3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.swiper-button-prev:hover, .swiper-button-next:hover{
    background-color: #508F89 !important;
    color: #fff !important;
}
.swiper-button-next::after, .swiper-button-prev::after{
    font-size: 20px !important;
}
.swiper .swiper-button-prev {
    right: 0 !important;
    left: auto;
}
.swiper .swiper-button-next {
    left: 0 !important;
    right: auto;
}
.profile-images{
    max-width: 900px;
    width: 100%;
    margin: 50px auto 0;
}
.profile-images img{
    width: 100%;
}




.how-work-box{
    text-align: center;
    margin-bottom: 20px;
}
.how-work-icon{
    text-align: center;
    width: 70px;
    height: 70px;
    background-color: #118279;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #fff;
}
.how-work-content h3{
    font-weight: 600;
    font-size: 20px;
    margin: 15px 0 10px;
    color: #fff;
}
.how-work-content p{
    font-size: 16px;
    color: #c8c8c8;
}



.how-its-work-section {
    background: #508F89;
    padding: 50px 0;
}
.portfolio-slider-main .swiper-slide button{
    opacity: 0.3;
}
.portfolio-slider-main .swiper-slide button[data-headlessui-state="selected"]{
    opacity: 1;
}

.customer-slider-main .swiper-slide img{
    opacity: 0.3;
}
.customer-slider-main .swiper-slide-active img{
    opacity: 1;
}

.portfolio-slider-main .swiper-slide button:focus-visible{
    outline: none;
}
.portfolio-content-main {
    padding: 10px;
    padding-top: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    border: solid 1px rgb(11, 72, 67);
    border-radius: 5px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.portfolio-content-main h3{
    font-size: 24px;
    font-weight: 700;
    color: #28CCBD;
    margin-bottom: 10px;
}
.portfolio-content-main p{
    font-size: 16px;
    color: #c8c8c8;
    margin-top: 12px;
}

.contact-section{
    background-image: url('./../public/images/contact-bg.png');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
}




footer{
    padding: 20px 10px;
    text-align: center;
    background-color: #508F89;
    color: #fff;
}





@media (max-width: 767px){
    .bg-video-main{
        display: none;
    }
    .contact-section{
        background-size: cover;
    }
    .swiper-button-prev, .swiper-button-next{
        display: none !important;
    }
}